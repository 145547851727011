import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DashboardProducts.css";
import { SpinnerInfinity } from "spinners-react";
import DashboardProductDetails from "../DashboardProductDetails/DashboardProductDetails";
import { useNavigate } from "react-router-dom";

const DashboardProducts = ({ data }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const categoryColors = {
    Machinery: "#1F59BC1A",
    "Purchase Financing": "#C1E1C1",
    "Working Capital": "#BCA21F1A",
    "Food Products": "#BC1F261A",
    Default: "#F5F5F5",
  };

  const categoryColorsBackground = {
    Machinery: "#3379EC",
    "Purchase Financing": "#2F9E00",
    "Working Capital": "#FF9000",
    "Food Products": "#BC1F26",
    Default: "#F5F5F5",
  };

  const handleProductClick = (productId) => {
    navigate(`/dashboard/product/${productId}`);
  };

  useEffect(() => {
    setProducts(data);
  });

  return (
    <div id="dashboard-products">
      {products && products.length > 0 ? (
        products.map((item, index) => (
          <div
            id="product-card"
            key={item._id}
            onClick={() => handleProductClick(item._id)}
          >
            <div className="card-bg">
              <div className="name-logo">
                <img src={item.logo} alt="" />
                <h1>{item.productName}</h1>
              </div>
              <p
                style={{
                  backgroundColor:
                    categoryColors[item.categoryName] || categoryColors.Default,
                  color:
                    categoryColorsBackground[item.categoryName] ||
                    categoryColors.Default,
                }}
              >
                {item.categoryName}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="infinity-spinner">
          <SpinnerInfinity
            size={90}
            thickness={180}
            speed={105}
            color="rgba(172, 57, 59, 1)"
            secondaryColor="rgba(0, 0, 0, 0.78)"
          />
        </div>
      )}
    </div>
  );
};

export default DashboardProducts;
