import React, { useEffect, useState } from "react";
import "./DashboardContent.css";
import quoteImage from "./images/dashboard-quote-1.svg";
import helpImage from "./images/dashboard-help.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import DashboardProducts from "../DashboardProducts/DashboardProducts";
import pointerLogo from "./images/pointer_logo.svg";
import Horizontallscroll from "../../Miui/HorizontalScroll/Horizontallscroll";
import axios from "axios";
import toast from "react-hot-toast";
import DashboardProductDetails from "../DashboardProductDetails/DashboardProductDetails";

const DashboarContent = () => {
  const [selectedData, setSelectedData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");

  // const sampleItems = [
  //   "All",
  //   "Machinery",
  //   "Purchase Financing",
  //   "Working Capital",
  //   "Food Products",
  // ];

  // Fetch all products and categories on initial load

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products"
        ); // API to fetch all products
        const products = response.data.data;

        // Extract unique categories and set state
        const uniqueCategories = [
          "All",
          ...new Set(products.map((product) => product.categoryName)),
        ];
        setCategories(uniqueCategories);
        setSelectedData(products); // Show all products initially
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    fetchProducts();
  }, []);

  const handleCategoryClick = async (category) => {
    try {
      setActiveCategory(category);

      const endpoint =
        category === "All"
          ? "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products" // Fetch all products
          : `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products?categoryName=${category}`; // Fetch products by category
      const response = await axios.get(endpoint);

      setSelectedData(response.data.data);
    } catch (error) {
      console.error("Error fetching products for category:", error.message);
    }
  };

  return (
    <>
      <div id="dashboard-content">
        <div className="dashboard-left">
          <p> Choose what fits you best</p>

          <Horizontallscroll
            items={categories}
            onItemClick={handleCategoryClick}
          />

          <div className="custom-scrollbar">
            <DashboardProducts data={selectedData} />
          </div>
        </div>

        <div className="dashboard-right">
          <div className="pointer-logo">
            <img src={pointerLogo} alt="" />
            <p>Insights</p>
          </div>

          <div className="productDetails">
            <DashboardProductDetails data={selectedData} />
          </div>
        </div>
      </div>

      <div className="dashboard-footer">
        <div className="dashboard-footer-content">
          <p>
            Disclaimer: &nbsp;
            <span>
              Fractioned is committed to maintaining a rigorous evaluation
              process, conducted by our proficient finance team, to vet the
              credentials of each business featured on our platform. As an
              organization that prioritizes retail investors, we enforce
              stringent compliance standards to ensure only credible and
              authentic listings are made available for investment.
              Nevertheless, while these investments are generally backed by
              tangible assets, they are accompanied by a moderate level of risk,
              including potential delays in repayment. It is crucial to
              acknowledge that, although the probability of losing your
              principal investment is minimal, it remains a possibility. © 2024
              Fractioned Technologies & Business Management Private Limited. All
              rights reserved. | CIN: UXXXXXXXXXXXX Fractioned is an
              independent, SEBI-registered platform specializing in asset-backed
              investments across the MSME sector. | SEBI Registration Number:
              INZ000XXXXXX Registered Office: 4th floor, Ankit Saphire, 100 Feet
              Rd, Indiranagar, Bengaluru, Karnataka 560008 Contact Us: For
              queries or support, reach out to our customer service team: Email:
              hello@fractioned.capital | Phone: +91-XXXXXXXXXX Investor Safety
              and Awareness: We ensure transparency and provide regular updates
              to all investors. | For additional support, use SEBI's grievance
              redressal system [SCORES](https://www.scores.gov.in). | Important:
              Never share account credentials with unauthorized individuals or
              entities. Quick Links: Terms & Conditions, Privacy Policy,
              Investor Charter, Risk Disclosure, Compliance Guidelines
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default DashboarContent;
