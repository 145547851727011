// import React, { useState } from "react";
// import "./Seeproducts.css";
// import axios from "axios";
// import EditProduct from "../editProduct/EditProduct";
// import toast from "react-hot-toast";

// const SeeProducts = ({ products }) => {
//   const data = products;
//   console.log(data);
//   const [formData, setFormData] = useState({
//     productName: "",
//     minimumInvestment: "",
//     soldPercentage: "",
//     tenure: "",
//     XIRR: "",
//     categoryName: "",
//     categoryDescription: "",
//     activeDeals: "",
//     moneyRaised: "",
//     postDatedCheque: "",
//     purchaseOrder: "",
//     termOfInvestment: "",
//     logo: "",
//     kycDocuments: "",
//   });

//   const [editingProductId, setEditingProductId] = useState(null);

//   const handleEdit = async (id) => {
//     // console.log(id);
//     try {
//       const response = await axios.get(
//         `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products`
//       );
//       const product = response.data;
//       const data = product.data;
//       // console.log(data);

//       const selectedProduct = data.find((el) => el._id === id);

//       if (selectedProduct) {
//         setFormData({
//           productName: selectedProduct.productName || "",
//           minimumInvestment: selectedProduct.minimumInvestment || "",
//           soldPercentage: selectedProduct.soldPercentage || "",
//           tenure: selectedProduct.tenure || "",
//           XIRR: selectedProduct.XIRR || "",
//           categoryName: selectedProduct.categoryName || "",
//           categoryDescription: selectedProduct.categoryDescription || "",
//           activeDeals: selectedProduct.activeDeals || "",
//           moneyRaised: selectedProduct.moneyRaised || "",
//           postDatedCheque: selectedProduct.postDatedCheque || "",
//           purchaseOrder: selectedProduct.purchaseOrder || "",
//           termOfInvestment: selectedProduct.termOfInvestment || "",
//           logo: selectedProduct.logo || "",
//           kycDocuments: selectedProduct.kycDocuments || "",
//         });
//       } else {
//         console.error("No product found with the given id");
//       }
//       setEditingProductId(id);
//     } catch (error) {
//       toast.error(error);
//       console.log(error);
//     }
//   };

//   const handleDelete = async (id) => {
//     // console.log(id);
//     try {
//       const response = await axios.delete(
//         `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/deleteproduct/${id}`
//       );

//       // console.log(response.data.message);
//       toast.success(response.data.message);
//     } catch (error) {
//       toast.error(error);
//       console.log(error);
//     }
//   };

//   return (
//     <div>
//       {editingProductId && (
//         // <form onSubmit={handleSubmit}>
//         <div className="product-form">
//           <h2>Edit Product</h2>
//           <EditProduct
//             formData={formData}
//             editingProductId={editingProductId}
//           />
//         </div>
//         // </form>
//       )}

//       <div className="product-list">
//         <h2>Products</h2>
//         {data.length > 0 ? (
//           <ul>
//             {data.map((product, index) => (
//               <li key={index}>
//                 <div className="name-logo">
//                   <strong>{product.productName}</strong>
//                   <img src={product.logo} alt="" />
//                 </div>
//                 <p>Minimum Investment: {product.minimumInvestment}</p>
//                 <p>Sold Percentage: {product.soldPercentage}</p>
//                 <p>Tenure: {product.tenure}</p>
//                 <p>XIRR: {product.XIRR}</p>
//                 <p>Category: {product.categoryName}</p>
//                 <p>Category Description: {product.categoryDescription}</p>
//                 <p>Active Deals: {product.activeDeals}</p>
//                 <p>Money Raised: {product.moneyRaised}</p>
//                 <p>Post Dated Cheque:{product.postDatedCheque}</p>
//                 <p>Purchase Order: {product.purchaseOrder}</p>
//                 <p>Terms of Investment: {product.termOfInvestment}</p>
//                 <p>KYC Documents: {product.kycDocuments}</p>
//                 <div className="edit-delete-button">
//                   <button onClick={() => handleEdit(product._id)}>Edit</button>
//                   <button onClick={() => handleDelete(product._id)}>
//                     Delete
//                   </button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>products Loading</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SeeProducts;




import React, { useState } from "react";
import "./Seeproducts.css";
import axios from "axios";
import EditProduct from "../editProduct/EditProduct";
import toast from "react-hot-toast";

const SeeProducts = ({ products }) => {
  const data = products;
  console.log(data);
  const [formData, setFormData] = useState({
    productName: "",
    minimumInvestment: "",
    soldPercentage: "",
    tenure: "",
    XIRR: "",
    categoryName: "",
    categoryDescription: "",
    activeDeals: "",
    moneyRaised: "",
    postDatedCheque: "",
    purchaseOrder: "",
    termOfInvestment: "",
    logo: "",
    kycDocuments: "",
    coreStrength: {},
    possibleChallenges: {},
  });

  const [editingProductId, setEditingProductId] = useState(null);

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products`
      );
      const product = response.data;
      const data = product.data;

      const selectedProduct = data.find((el) => el._id === id);

      if (selectedProduct) {
        setFormData({
          productName: selectedProduct.productName || "",
          minimumInvestment: selectedProduct.minimumInvestment || "",
          soldPercentage: selectedProduct.soldPercentage || "",
          tenure: selectedProduct.tenure || "",
          XIRR: selectedProduct.XIRR || "",
          categoryName: selectedProduct.categoryName || "",
          categoryDescription: selectedProduct.categoryDescription || "",
          activeDeals: selectedProduct.activeDeals || "",
          moneyRaised: selectedProduct.moneyRaised || "",
          postDatedCheque: selectedProduct.postDatedCheque || "",
          purchaseOrder: selectedProduct.purchaseOrder || "",
          termOfInvestment: selectedProduct.termOfInvestment || "",
          logo: selectedProduct.logo || "",
          kycDocuments: selectedProduct.kycDocuments || "",
          coreStrength: selectedProduct.coreStrength || {},
          possibleChallenges: selectedProduct.possibleChallenges || {},
        });
      } else {
        console.error("No product found with the given id");
      }
      setEditingProductId(id);
    } catch (error) {
      toast.error(error.message || "Error fetching product details");
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/deleteproduct/${id}`
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message || "Error deleting product");
      console.log(error);
    }
  };

  return (
    <div>
      {editingProductId && (
        <div className="product-form">
          <h2>Edit Product</h2>
          <EditProduct
            formData={formData}
            editingProductId={editingProductId}
          />
        </div>
      )}

      <div className="product-list">
        <h2>Products</h2>
        {data.length > 0 ? (
          <ul>
            {data.map((product, index) => (
              <li key={index}>
                <div className="name-logo">
                  <strong>{product.productName}</strong>
                  <img src={product.logo} alt="Product Logo" />
                </div>
                <p>Minimum Investment: {product.minimumInvestment}</p>
                <p>Sold Percentage: {product.soldPercentage}</p>
                <p>Tenure: {product.tenure}</p>
                <p>XIRR: {product.XIRR}</p>
                <p>Category: {product.categoryName}</p>
                <p>Category Description: {product.categoryDescription}</p>
                <p>Active Deals: {product.activeDeals}</p>
                <p>Money Raised: {product.moneyRaised}</p>
                <p>Post Dated Cheque: {product.postDatedCheque}</p>
                <p>Purchase Order: {product.purchaseOrder}</p>
                <p>Terms of Investment: {product.termOfInvestment}</p>
                <p>KYC Documents: {product.kycDocuments}</p>

                {/* Render coreStrength dynamically */}
                <div className="core-strength">
                  <h4>Core Strengths:</h4>
                  <ul>
                    {product.coreStrength &&
                      Object.entries(product.coreStrength).map(
                        ([key, value]) => (
                          <li key={key}>{value}</li>
                        )
                      )}
                  </ul>
                </div>

                {/* Render possibleChallenges dynamically */}
                <div className="possible-challenges">
                  <h4>Possible Challenges:</h4>
                  <ul>
                    {product.possibleChallenges &&
                      Object.entries(product.possibleChallenges).map(
                        ([key, value]) => (
                          <li key={key}>{value}</li>
                        )
                      )}
                  </ul>
                </div>

                <div className="edit-delete-button">
                  <button onClick={() => handleEdit(product._id)}>Edit</button>
                  <button onClick={() => handleDelete(product._id)}>
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Products Loading</p>
        )}
      </div>
    </div>
  );
};

export default SeeProducts;
