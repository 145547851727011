import React, { useState } from "react";
import toast from "react-hot-toast";

const EditProduct = ({ formData, editingProductId }) => {
  // console.log(formData, editingProductId);

  const id = editingProductId;

  const [editFormData, setEditFormData] = useState({
    // productName: formData.productName,
    // logo: formData.logo,
    // minimumInvestment: formData.minimumInvestment,
    // soldPercentage: formData.soldPercentage,
    // tenure: formData.tenure,
    // XIRR: formData.XIRR,
    // categoryName: formData.categoryName,
    // categoryDescription: formData.categoryDescription,
    // activeDeals: formData.activeDeals,
    // moneyRaised: formData.moneyRaised,
    // postDatedCheque: formData.postDatedCheque,
    // purchaseOrder: formData.purchaseOrder,
    // termOfInvestment: formData.termOfInvestment,
    // coreStrength: formData.coreStrength,
    // possibleChallenges: formData.possibleChallenges,
    // kycDocuments: formData.kycDocuments,

    ...formData,
    coreStrength: {
      strength1: formData.coreStrength?.strength1 || "",
      strength2: formData.coreStrength?.strength2 || "",
      strength3: formData.coreStrength?.strength3 || "",
    },
    possibleChallenges: {
      challenge1: formData.possibleChallenges?.challenge1 || "",
      challenge2: formData.possibleChallenges?.challenge2 || "",
      challenge3: formData.possibleChallenges?.challenge3 || "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // console.log(`Updating field: ${name}: Value: ${value}`);
    // setEditFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));

    // console.log("Form data", formData);
    
    if (name.startsWith('strength')) {
      setEditFormData(prevState => ({
        ...prevState,
        coreStrength: {
          ...prevState.coreStrength,
          [name]: value
        }
      }));
    }
    // Handle nested fields for possibleChallenges
    else if (name.startsWith('challenge')) {
      setEditFormData(prevState => ({
        ...prevState,
        possibleChallenges: {
          ...prevState.possibleChallenges,
          [name]: value
        }
      }));
    }
    // Handle other fields
    else {
      setEditFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/updateproduct/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editFormData),
        }
      );
      const result = await response.json();
      setLoading(false);
      toast.success("Product created successfully");

      if (response.ok) {
        setMessage("Product added successfully!");
        setEditFormData({
          productName: "",
          logo: "",
          minimumInvestment: "",
          soldPercentage: "",
          tenure: "",
          XIRR: "",
          categoryName: "",
          categoryDescription: "",
          activeDeals: "",
          moneyRaised: "",
          postDatedCheque: "",
          purchaseOrder: "",
          termOfInvestment: "",
          coreStrength: "",
          possibleChallenges: "",
          kycDocuments: "",
        });
      } else {
        setMessage(result.message || "Failed to add product.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="product-form-div">
      <form id="create-product-form" onSubmit={handleSubmit}>
        <div>
          <label>Product Name:</label>

          <input
            className="input-field"
            type="text"
            name="productName"
            value={editFormData.productName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Logo:</label>
          <input
            className="input-field"
            type="text"
            name="logo"
            value={editFormData.logo}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Minimum Investment:</label>
          <input
            type="number"
            name="minimumInvestment"
            value={editFormData.minimumInvestment}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Sold Percentage:</label>
          <input
            type="number"
            name="soldPercentage"
            value={editFormData.soldPercentage}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Tenure:</label>
          <input
            type="number"
            name="tenure"
            value={editFormData.tenure}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>XIRR:</label>
          <input
            type="number"
            name="XIRR"
            value={editFormData.XIRR}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Name:</label>
          <input
            type="text"
            name="categoryName"
            value={editFormData.categoryName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Description:</label>
          <textarea
            type="text"
            name="categoryDescription"
            value={editFormData.categoryDescription}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Active Deals:</label>
          <textarea
            type="number"
            name="activeDeals"
            value={editFormData.activeDeals}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Money Raised:</label>
          <textarea
            type="number"
            name="moneyRaised"
            value={editFormData.moneyRaised}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Post Dated Cheque:</label>
          <textarea
            type="text"
            name="postDatedCheque"
            value={editFormData.postDatedCheque}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Purchase Order:</label>
          <textarea
            type="text"
            name="purchaseOrder"
            value={editFormData.purchaseOrder}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Terms of Investment:</label>
          <textarea
            type="text"
            name="termOfInvestment"
            value={editFormData.termOfInvestment}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>CoreStrength1:</label>
          <input
            type="text"
            name="strength1"
            value={editFormData.coreStrength?.strength1 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>CoreStrength2:</label>
          <input
            type="text"
            name="strength2"
            value={editFormData.coreStrength?.strength2 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>CoreStrength3:</label>
          <input
            type="text"
            name="strength3"
            value={editFormData.coreStrength?.strength3 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>PossibleChallenge1:</label>
          <input
            type="text"
            name="challenge1"
            value={editFormData.possibleChallenges?.challenge1 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>PossibleChallenge2:</label>
          <input
            type="text"
            name="challenge2"
            value={editFormData.possibleChallenges?.challenge2 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>PossibleChallenge3:</label>
          <input
            type="text"
            name="challenge3"
            value={editFormData.possibleChallenges?.challenge3 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>kycDocuments:</label>
          <textarea
            type="text"
            name="kycDocuments"
            value={editFormData.kycDocuments}
            onChange={handleChange}
          ></textarea>
        </div>

        <button className="product-create-btn" type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Create Product"}
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
