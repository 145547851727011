import React, { useEffect, useState } from "react";
import "./DashboardProductDetails.css";
import { RiInformation2Line } from "react-icons/ri";
import invest from "./Invest.svg";
import onTimePayment from "./on-time-payment.svg";
import moneyRaised from "./money-raised.svg";
import coreStrength from "./core-strength.svg";
import possibleChallenges from "./possible-challenges.svg";
import coreStrengthImg from "./core-strength-img.svg";
import possibleChallengesImg from "./possible-challenges-img.svg";
import Modal from "react-modal";
import documentDownload from "./document-download.svg";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import founder_logo from "./abhishek_bothra.jpg";
import axios from "axios";
import Cookies from "js-cookie";
Modal.setAppElement("#root");

const DashboardProductDetails = (data) => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [productDetails, setProductDetails] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalContent, setModalContent] = useState("");

  const [content, setContent] = useState("");

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (button) => {
    console.log(button);
    setActiveButton(button);
  };

  const handlePostDatedCheque = (doc) => {
    if (doc) {
      window.open(doc, "_blank", "noopener, noreferrer");
    } else {
      toast.error("Post-dated cheque Document is not available.");
    }
  };

  const handlePurchaseorder = (doc) => {
    if (doc) {
      window.open(doc, "_blank", "noopener, noreferrer");
    } else {
      toast.error("Purchase Order Document is not available.");
    }
  };

  const handleTermsOfInvestment = (doc) => {
    if (doc) {
      window.open(doc, "_blank", "noopener, noreferrer");
    } else {
      toast.error("Term of Investment Document is not available.");
    }
  };

  const handleKycDocuments = (doc) => {
    if (doc) {
      window.open(doc, "_blank", "noopener, noreferrer");
    } else {
      toast.error("KYC Document is not available.");
    }
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      console.log(productId);

      try {
        if (productId) {
          const response = await axios.get(
            `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/product/${productId}`
          ); // Fetch product details by ID
          setProductDetails(response.data.data);
          navigate(`/dashboard/product/${productId}`);
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
        // setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    if (data && data.data && Array.isArray(data.data)) {
      setProductDetails(data.data[0]);
    } else {
      console.error("Invalid data format:", data);
    }
  }, [data]);

  const formatCurrency = (amount) => {
    if (!amount) return "0";

    if (amount >= 1e7) {
      return `₹ ${(amount / 1e7).toFixed(2)} Crore`;
    } else if (amount >= 1e5) {
      return `₹ ${(amount / 1e5).toFixed(2)} Lakh`;
    } else {
      return `₹ ${amount.toLocaleString("en-IN")}`;
    }
  };

  const openModal = (content) => {
    if (content === "tenure") {
      setContent("Tenure");
      setModalContent(
        "Flexible investment durations starting at 2 months and extending up to 60 months, tailored to suit your financial goals."
      );
    } else if (content === "XIRR") {
      setContent("Internal Rate of Return (XIRR)");
      setModalContent(
        "XIRR refers to the internal rate of return for the investment, annualized."
      );
    } else if (content === "activeDeals") {
      setContent("Active Deals");
      setModalContent(
        "Investments are evaluated across 50+ parameters, ensuring opportunities are carefully vetted for financial health, market conditions, and stability."
      );
    } else if (content === "onTimePayment") {
      setContent("On-Time Payments");
      setModalContent(
        "Our platform ensures prompt payment collections from businesses, supported by robust safeguards like eNACH and undated cheques, providing you with a reliable income stream."
      );
    } else if (content === "raisedMoney") {
      setContent("Raised Money");
      setModalContent(
        "Successfully raised substantial funds to support MSME growth while offering investors lucrative returns backed by real-world assets."
      );
    } else if (content === "Thank You for Your Interest !") {
      setContent(content);
      setModalContent(
        "We're excited about your decision to invest. Just a moment-our team will reach out to you as soon as possible. Stay tuned!"
      );
    } else {
      setModalContent("More details are available.");
    }
    setIsModalOpen(true);
  };

  // Handler to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  const handleInvestNow = async (id) => {
    console.log(id);
    const token = Cookies.get("token");
    console.log(token);
    try {
      const res = await axios.post(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/user/${id}/invest`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(res.data.message);
      openModal("Thank You for Your Interest !");
      setIsModalOpen(true);
    } catch (error) {
      setSuccessMessage("Failed to invest. Please try again.");
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div id="dashboard_product_details">
        <div className="product_details_card">
          <div className="card-top-row">
            <p>{productDetails?.productName}</p>
            <div>
              <img
                src={productDetails?.logo}
                alt="image"
                style={{ borderRadius: "5px" }}
              />
            </div>
          </div>

          <div className="card-mid-row">
            <p>{productDetails?.categoryName}</p>
          </div>

          <div id="card-last-row">
            <div className="card-details">
              <div className="tenure-xirr">
                <p>TENURE</p>
                <RiInformation2Line
                  color="#f2f2f2"
                  onClick={() => openModal("tenure")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p>{productDetails?.tenure} days</p>
            </div>

            <div className="card-details">
              <div className="tenure-xirr">
                <p>XIRR</p>
                <RiInformation2Line
                  color="#f2f2f2"
                  onClick={() => openModal("XIRR")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p>
                {productDetails?.XIRR}% <span>/ annum</span>
              </p>
            </div>
          </div>
        </div>
        <div className="investment-btn-card">
          <p className="heading">Minimum Investment Amount:</p>
          <div className="btn-card-mid">
            <p>₹</p>
            <p>{productDetails?.minimumInvestment}</p>
          </div>
          <button onClick={() => handleInvestNow(productDetails?._id)}>
            Go Invest
          </button>
        </div>
      </div>

      <div className="mid-section">
        <div className="mid-section-content">
          <div className="mid-section-content-left">
            <img src={invest} alt="image" />
          </div>
          <div className="mid-section-content-right">
            <div>
              <p>ACTIVE</p>
              <RiInformation2Line
                color="#757677"
                onClick={() => openModal("activeDeals")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>{productDetails?.activeDeals} Deals</p>
          </div>
        </div>

        <div className="mid-section-content">
          <div className="mid-section-content-left">
            <img src={onTimePayment} alt="image" />
          </div>
          <div className="mid-section-content-right">
            <div>
              <p>ON TIME PAYMENT</p>
              <RiInformation2Line
                color="#757677"
                onClick={() => openModal("onTimePayment")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>100%</p>
          </div>
        </div>

        <div className="mid-section-content">
          <div className="mid-section-content-left">
            <img src={moneyRaised} alt="image" />
          </div>
          <div className="mid-section-content-right">
            <div>
              <p>RAISED</p>
              <RiInformation2Line
                color="#757677"
                onClick={() => openModal("raisedMoney")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>{formatCurrency(productDetails?.moneyRaised)}</p>
          </div>
        </div>
      </div>

      <div className="mid-section-bottom">
        <div className="mid-section-btm-col">
          <button onClick={() => handleButtonClick("coreStrength")}>
            <img src={coreStrength} alt="image" />
            <p>Core Strengths</p>
          </button>

          <button onClick={() => handleButtonClick("possibleChallenges")}>
            <img src={possibleChallenges} alt="image" />
            <p>Possible Challenges</p>
          </button>
        </div>
      </div>

      <div className="corestrength-possiblechallenges">
        {activeButton === "coreStrength" && (
          <div>
            <ul>
              <li>
                <img src={coreStrengthImg} alt="image" />
                {productDetails.coreStrength["strength1"]}
              </li>
              <li>
                <img src={coreStrengthImg} alt="image" />
                {productDetails.coreStrength["strength2"]}
              </li>
              <li>
                <img src={coreStrengthImg} alt="image" />
                {productDetails.coreStrength["strength3"]}
              </li>
            </ul>
          </div>
        )}

        {activeButton === "possibleChallenges" && (
          <div>
            <ul>
              <li>
                <img src={possibleChallengesImg} alt="image" />
                {productDetails.possibleChallenges["challenge1"]}
              </li>
              <li>
                <img src={possibleChallengesImg} alt="image" />
                {productDetails.possibleChallenges["challenge2"]}
              </li>
              <li>
                <img src={possibleChallengesImg} alt="image" />
                {productDetails.possibleChallenges["challenge3"]}
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* <div className="bottom-section-documents" allowToggle>
        
      </div> */}

      <div className="bottom-section-documents">
        <p>View Legal Documents</p>

        <div className="bottom-section-row">
          <div>
            <div>
              <p>Post-dated Cheque</p>
              <p>PDF</p>
            </div>
            <img
              src={documentDownload}
              alt="image"
              onClick={() =>
                handlePostDatedCheque(productDetails?.postDatedCheque)
              }
            />
          </div>
          <div>
            <div>
              <p>Purchase Order</p>
              <p>PDF</p>
            </div>
            <img
              src={documentDownload}
              alt="image"
              onClick={() => handlePurchaseorder(productDetails?.purchaseOrder)}
            />
          </div>
        </div>

        <div className="bottom-section-row">
          <div>
            <div>
              <p>Terms of Investment</p>
              <p>PDF</p>
            </div>
            <img
              src={documentDownload}
              alt="image"
              onClick={() =>
                handleTermsOfInvestment(productDetails?.termOfInvestment)
              }
            />
          </div>
          <div>
            <div>
              <p>KYC Document</p>
              <p>PDF</p>
            </div>
            <img
              src={documentDownload}
              alt="image"
              onClick={() => handleKycDocuments(productDetails?.kycDocuments)}
            />
          </div>
        </div>
      </div>

      {content === "Thank You for Your Interest !" ? (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          className="modal-content-unique"
        >
        <div>
          <p>{content}</p>
          <img src={founder_logo} alt="image" style={{ height: "100px", borderRadius:"50%"}} />
        </div>
          <p>{modalContent}</p>
        </Modal>
      ) : (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          className="modal-content"
        >
          <p>{content} </p>
          <p>{modalContent}</p>
        </Modal>
      )}
    </>
  );
};

export default DashboardProductDetails;
