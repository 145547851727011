import React from "react";
import "./NavbarDashboard.css";
import logo from "./LOGO copy.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import LogoutIcon from "./logout_icon.svg";

const NavbarDashboard = () => {
  const { isAuth, isToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("token");
    dispatch(logout());
    toast.success("User logged out successfully");
    navigate("/homeabhi9331553831");
  };

  return (
    <div id="navbar-container">
      <div className="fractioned-logo">
        <Link to="/homeabhi9331553831">
          <img src={logo} alt="fractioned-logo" />
        </Link>
      </div>

      <div className="login-logout-btn" onClick={handleLogout}>
        <div>
          <img src={LogoutIcon} alt="logout_icon" />
        </div>
        <div>
          <button>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default NavbarDashboard;
