import React, { useEffect, useRef, useState } from "react";
import "./AdminDashboard.css";
import CreateProductForm from "../createProductForm/CreateProductForm";
import SeeProducts from "../seeProducts/SeeProducts";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const AdminDashboard = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();
  const hasNavigated = useRef(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const isAuthenticated = Cookies.get("isAuthenticated");

    if (isAuthenticated !== "true" && !hasNavigated.current) {
      toast.error("Please Login as Admin");
      hasNavigated.current = true;
      navigate("/admin-login");
    }
  }, [navigate]);

  const toggleForm = () => {
    setIsFormVisible((prevState) => !prevState);
    setIsProductsVisible(false);
  };

  const toggleProducts = async () => {
    setIsProductsVisible((prevState) => {
      const newState = !prevState;
      if (newState) {
        fetchProducts(); // Fetch products only when visible
      }
      return newState;
    });
    setIsFormVisible(false);
  };

  const fetchProducts = async () => {
    try {
      const res = await fetch(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/"
      );
      const result = await res.json();
      setProducts(result.data);
      // console.log("Fetched products:", result.data); 
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <div id="admin-dashboard">
      <h1>Welcome to Admin Dashboard</h1>
      <div className="product-handle-button">
        <button className="create-product" onClick={toggleForm}>
          CREATE A PRODUCT
        </button>
        <button className="see-product" onClick={toggleProducts}>
          SEE PRODUCTS
        </button>
      </div>
      {isFormVisible && (
        <div className="product-form">
          <h2>Create Product</h2>
          <CreateProductForm />
        </div>
      )}
      {isProductsVisible && <SeeProducts products={products} />}
    </div>
  );
};

export default AdminDashboard;
