import React from "react";
import { Routes, Route } from "react-router-dom";
import JoinTheClub from "../pages/JoinTheClub/JoinTheClub";
import Home from "../Home/Home";
import Check from "../pages/check/Check";
import Login from "../pages/login/Login";
import LoginOtp from "../pages/login-otp/LoginOtp";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import Dashboard from "../dashboard/Dashboard";
import AdminLogin from "../admin/login/AdminLogin";
import AdminDashboard from "../admin/dashboard/AdminDashboard";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Construction from "../pages/underConstructionPage/Construction";
import MyAccordion from "../Miui/Accordion/MyAccordion";
import DashboardProductDetails from "../dashboard/DashboardProductDetails/DashboardProductDetails";
import DashboarContent from "../dashboard/DashboardContent.jsx/DashboarContent";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<JoinTheClub />} />
        <Route path="/homeabhi9331553831" element={<Home />} />
        <Route path="/check" element={<Check />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-otp" element={<LoginOtp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/strategy" element={<Construction />} />
        <Route path="/product" element={<Construction />} />
        <Route path="/about-us" element={<Construction />} />
        <Route
          // path="/dashboard"
          path="/dashboard/product/:productId"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />

        <Route path="/faq" element={<MyAccordion />} to="#sixth-section" />
      </Routes>
    </>
  );
};

export default AllRoutes;
