import React, { useState } from "react";
import "./createProduct.css";
import toast from "react-hot-toast";

const CreateProductForm = () => {
  const [formData, setFormData] = useState({
    productName: "",
    minimumInvestment: "",
    soldPercentage: "",
    tenure: "",
    XIRR: "",
    categoryName: "",
    categoryDescription: "",
    activeDeals: "",
    moneyRaised: "",
    postDatedCheque: "",
    purchaseOrder: "",
    termOfInvestment: "",
    logo: "",
    coreStrength: {
      strength1: "",
      strength2: "",
      strength3: "",
    },
    possibleChallenges: {
      challenge1: "",
      challenge2: "",
      challenge3: "",
    },
    kycDocuments: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Name: ${name}, Value: ${value}`);
    // console.log(`Updating field: ${name}: Value: ${value}`);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));

    // console.log("Form data", formData);

    if (name.startsWith("strength")) {
      setFormData((prevState) => ({
        ...prevState,
        coreStrength: {
          ...prevState.coreStrength,
          [name]: value,
        },
      }));
    } else if (name.startsWith("challenge")) {
      setFormData((prevState) => ({
        ...prevState,
        possibleChallenges: {
          ...prevState.possibleChallenges,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    console.log(formData);
    try {
      const response = await fetch(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/addproduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      console.log(result);
      setLoading(false);
      toast.success("Product created successfully");

      if (response.ok) {
        setMessage("Product added successfully!");
        setFormData({
          productName: "",
          minimumInvestment: "",
          soldPercentage: "",
          tenure: "",
          XIRR: "",
          categoryName: "",
          categoryDescription: "",
          activeDeals: "",
          moneyRaised: "",
          postDatedCheque: "",
          purchaseOrder: "",
          termOfInvestment: "",
          logo: "",
          coreStrength: {
            strength1: "",
            strength2: "",
            strength3: "",
          },
          possibleChallenges: {
            challenge1: "",
            challenge2: "",
            challenge3: "",
          },
          kycDocuments: "",
        });
      } else {
        setMessage(result.message || "Failed to add product.");
        toast.error("Failed to add product");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="product-form-div">
      <form id="create-product-form" onSubmit={handleSubmit}>
        <div>
          <label>Product Name:</label>

          <input
            className="input-field"
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Logo:</label>
          <input
            type="text"
            name="logo"
            value={formData.logo}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Minimum Investment:</label>
          <input
            type="number"
            name="minimumInvestment"
            value={formData.minimumInvestment}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Sold Percentage:</label>
          <input
            type="number"
            name="soldPercentage"
            value={formData.soldPercentage}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Tenure:</label>
          <input
            type="number"
            name="tenure"
            value={formData.tenure}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>XIRR:</label>
          <input
            type="number"
            name="XIRR"
            value={formData.XIRR}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Name:</label>
          <input
            type="text"
            name="categoryName"
            value={formData.categoryName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Description:</label>
          <textarea
            type="text"
            name="categoryDescription"
            value={formData.categoryDescription}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Active Deals:</label>
          <textarea
            type="number"
            name="activeDeals"
            value={formData.activeDeals}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Money Raised:</label>
          <textarea
            type="number"
            name="moneyRaised"
            value={formData.moneyRaised}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Post Dated Cheque:</label>
          <textarea
            type="text"
            name="postDatedCheque"
            value={formData.postDatedCheque}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Purchase Order:</label>
          <textarea
            type="text"
            name="purchaseOrder"
            value={formData.purchaseOrder}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Terms of Investment:</label>
          <textarea
            type="text"
            name="termOfInvestment"
            value={formData.termOfInvestment}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>CoreStrength1:</label>
          <input
            type="text"
            name="strength1"
            value={formData.coreStrength?.strength1 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>CoreStrength2:</label>
          <input
            type="text"
            name="strength2"
            value={formData.coreStrength?.strength2 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>CoreStrength3:</label>
          <input
            type="text"
            name="strength3"
            value={formData.coreStrength?.strength3 || ""}
            onChange={handleChange}
          />
        </div>

        {/* Add new fields for possibleChallenges */}
        <div>
          <label>PossibleChallenge1:</label>
          <input
            type="text"
            name="challenge1"
            value={formData.possibleChallenges?.challenge1 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>PossibleChallenge2:</label>
          <input
            type="text"
            name="challenge2"
            value={formData.possibleChallenges?.challenge2 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>PossibleChallenge3:</label>
          <input
            type="text"
            name="challenge3"
            value={formData.possibleChallenges?.challenge3 || ""}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>kycDocuments:</label>
          <textarea
            type="text"
            name="kycDocuments"
            value={formData.kycDocuments}
            onChange={handleChange}
          ></textarea>
        </div>

        <button className="product-create-btn" type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Create Product"}
        </button>
      </form>
    </div>
  );
};

export default CreateProductForm;
