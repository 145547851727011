import React, { useRef, useState, useEffect } from "react";
import "./HorizontallScroll.css";

const HorizontalScroll = ({ items, onItemClick }) => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div id="relative">
      <div
        ref={scrollContainerRef}
        style={{
          overflow: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          WebkitScrollbar: "none",
        }}
        className={`flex overflow-x-scroll scroll-smooth whitespace-nowrap gap-2 py-3 cursor-grab ${
          isDragging ? "cursor-grabbing" : ""
        } select-none scrollbar-hide
        `}
      >
        {items.map((item, index) => (
          <div
            key={index}
            id="item"
            className="flex-shrink-0 border flex px-2 items-center justify-center text-center rounded-lg transition-colors duration-300 item border-r-8"
            onClick={() => onItemClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScroll;
